body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fall {
  0% {
    transform: translateY(0) translateX(0) rotate(var(--start-rotation));
  }
  100% {
    transform: translateY(var(--end-y)) translateX(var(--end-x)) rotate(var(--end-rotation));
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1.0;
  }
  85% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
  }
}